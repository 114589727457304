.wrapper {
  display: grid;
  grid-template-columns: repeat(17, 1fr);
  grid-gap: 0px;
  grid-auto-rows: minmax(30px, auto);
  width: 98%;
  margin: auto;
  background: white;
  font-size: 8pt;
  font-weight: 500;
}

.title{
  margin-left: 20px;
  font-size: 18px;
  font-weight: 400;
}

.wrapper div{
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.one {
  grid-column: 1;
  grid-row: 1/3;
}
.includeOne {
  grid-column: 2 / 4;
  grid-row: 1 / 2;
}
.includeTwo {
  grid-column: 4 / 7;
  grid-row: 1 / 2;
}
.includeThree {
  grid-column: 7 / 11;
  grid-row: 1 / 2;
}
.two {
  grid-column: 2 / 2;
  grid-row: 2;
}
.three {
  grid-column: 3 / 3;
  grid-row: 2;
}
.four {
  grid-column: 4 / 4;
  grid-row: 2;
}
.five {
  grid-column: 5 / 5;
  grid-row: 2;
}
.six {
  grid-column: 6 / 6;
  grid-row: 2;
}

.seven {
  grid-column: 7 / 7;
  grid-row: 2;
}
.eight {
  grid-column: 8 / 8;
  grid-row: 2;
}
.nine {
  grid-column: 9 / 9;
  grid-row:  2;
}
.ten {
  grid-column: 10 / 10;
  grid-row:  2;
}
.eleven {
  grid-column: 11 / 11;
  grid-row:  1/3;
}
.twelve {
  grid-column: 12 / 12;
  grid-row: 1/3;
}

.thirteen {
  grid-column: 13 / 13;
  grid-row: 1/3;
}
.fourteen {
  grid-column: 14 / 14;
  grid-row: 1/3;

}
.fifteen {
  grid-column: 15 / 15;
  grid-row: 1/3;

}
.sixteen {
  grid-column: 16 / 16;
  grid-row: 1/3;
  border-left: 3px solid black!important;
}
.seventeen {
  grid-column: 17 / 17;
  grid-row: 1/3;

}
.yellow{
  background: rgba(255, 245, 102, 0.432);;
}

.green{
  background:  rgba(153, 255, 102, 0.4);
}

.red{
 background: rgba(255, 51, 0, 0.274);
}

.wrapper div.item15{
  border-left: 3px solid black;
  font-size: 12px;
}

.wrapper div.item16{
  font-size: 12px;
  font-weight: bold;
}

.wrapper div.maximum{
  background-color: #4ec0e4;
}
